<script>
// Import Vue framework for building the application
import Vue from "vue";

// Import layout and components for the page
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// Import HTTP request handling module
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
import Role from "@/data/Roles";

// Initialize instances of Role, HttpRequest, and Preference
let roleChecker = new Role();
let httpRequest = new HttpRequest();
let preference = new Preference();

// Export the Vue component configuration
export default {
  data() {
    // Data properties for the Vue component
    return {
      title: "Departments", // Title for the page
      access_property: "Department", // Access property for the page
      role: preference.getData(Preference.KEY_ROLE_ID), // User role
      myName: preference.getData(Preference.KEY_NAME), // User name
      myEmail: preference.getData(Preference.KEY_EMAIL), // User email
      myOrgRole: "5", // User organization role
      items: [
        // Breadcrumb items for navigation
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Departments",
          active: true,
        },
      ],
      organizationFields: [
        // Fields for the organization table
        { key: "name", sortable: true, label: "Name" },
        { key: "address", sortable: false, label: "Details" },
      ],
      orgMemberFields: [
        // Fields for the organization members table
        { key: "name", sortable: true, label: "Name" },
        { key: "email", sortable: true, label: "Email" },
        { key: "role_id", sortable: false, label: "Role" },
      ],
      currentRoleList: [], // List of current roles
      orgMembersList: [], // List of organization members
      organizationTableData: [], // Data for the organization table
      orgMemberTableData: [], // Data for the organization members table
      organizationList: [], // List of organizations
      totalRowsOrg: 1, // Total rows for organization table
      totalRowsMembers: 1, // Total rows for organization members table
      orgCurrentPage: 1, // Current page for organization table
      orgPerPage: 10, // Organizations per page
      orgPageOptions: [10, 25, 50], // Page options for organization table
      memberPerPage: 10, // Members per page
      memberPageOptions: [10, 25, 50], // Page options for organization members table
      memberCurrentPage: 1, // Current page for organization members table
      filterOrg: null, // Filter for organization table
      filterOrgMembers: null, // Filter for organization members table
      filterOnOrg: [], // Filter options for organization table
      filterOnMembers: [], // Filter options for organization members table
      sortBy: "orderid", // Default sort column
      sortDesc: false, // Default sort order
      selected: {}, // Selected items
      editedItem: "", // Edited item
      selectedOrgName: "", // Selected organization name
      selectedOrganizationID: 1, // Selected organization ID
      roleIDMap: {}, // Map of role IDs
      displayValueMap: {}, // Map of display values
      memberUserEmail: "", // Member user email
      memberUserName: "", // Member user name
      memberUserRole: "Member", // Member user role
      organizationID: "", // Organization ID
      roleObjectList: {}, // List of roles
      organizationMap: {}, // Map of organizations
      org_name: "", // Organization name
      user_id: "", // User ID
      org_address: "", // Organization address
      loadingOrganizations: false, // Loading indicator for organizations
      loadingMembers: false, // Loading indicator for members
      roleEditItem: "", // Role edit item
    };
  },

  computed: {
    // Compute the total number of rows in the organization list
    orgRows() {
      return this.organizationList.length;
    },
    // Compute the total number of rows in the organization members list
    memberRows() {
      return this.orgMembersList.length;
    },
  },

  mounted() {
    // Call the addColumn method with the current user role
    this.addColumn(this.role);

    // Set the totalRowsOrg and totalRowsMembers based on the initial lengths of the lists
    this.totalRowsOrg = this.organizationList.length;
    this.totalRowsMembers = this.orgMembersList.length;
  },

  components: {
    // Import and use the Layout and PageHeader components
    Layout,
    PageHeader,
  },
  name: "projects", // Name of the Vue component

  watch: {
    // Watch for changes in the filterOrgMembers and reset the memberCurrentPage to 1
    filterOrgMembers() {
      this.memberCurrentPage = 1;
    },
  },

  methods: {
    isRoleAccess(role_id, resource, action) {
      // Check if the user has access to a specific resource and action
      return roleChecker.checkAccess(role_id.toString(), resource, action);
    },

    doubleRoleCheck(myRole, myRoleOrg, access_property, action) {
      // Perform a double role check for access to a specific property and action
      if (
        this.isRoleAccess(myRole, access_property, action) ||
        this.isRoleAccess(myRoleOrg, access_property, action)
      ) {
        return true; // Return true if either of the roles grants access
      }
      return false; // Return false if neither role grants access
    },
    addColumn(myRole) {
      // Add a column for 'Action' in the organization table if user has 'read' access
      if (this.isRoleAccess(myRole, this.access_property, "read")) {
        this.organizationFields.push({ key: "action", sortable: false, label: "Action" });
      }

      // Add a column for 'Action' in the organization members table if user has 'read' access as a member
      if (this.isRoleAccess(myRole, "Member", "read")) {
        this.orgMemberFields.push({ key: "action", sortable: false, label: "Action" });
      }
    },
    createOrganization() {
      // Initialize local variable for component instance
      let _this = this;

      // Check if the user has write access to create an organization
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myOrgRole,
        this.access_property,
        "write"
      );

      // Set loading indicator for organization creation
      _this.loadingOrganizations = true;

      // Initialize sweet alert for displaying messages
      let swal = this.$swal;

      // Call the createOrganization method in the HttpRequest module
      httpRequest
        .createOrganization(
          _this.org_name,
          "0",
          _this.user_id,
          _this.org_address,
          accessPermission
        )
        .then(function (response) {
          // Reset loading indicator after the request is completed
          _this.loadingOrganizations = false;

          // Check the success status of the response
          if (response.success) {
            // Hide the organization creation modal and refresh the organization list
            _this.$bvModal.hide("modal-add-org");
            _this.getOrganizationList();
          } else {
            // Display a sweet alert with the error message from the response
            swal(response.message);
          }
        });
    },

    editOrganization() {
      // Initialize local variable for component instance
      let _this = this;

      // Set loading indicator for organization editing
      _this.loadingOrganizations = true;

      // Check if the user has update access to edit an organization
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myOrgRole,
        this.access_property,
        "update"
      );

      // Initialize sweet alert for displaying messages
      let swal = this.$swal;

      // Call the editOrganization method in the HttpRequest module
      httpRequest
        .editOrganization(
          _this.editedItem.name,
          "0",
          _this.user_id,
          _this.editedItem.address,
          _this.editedItem.organization_id,
          accessPermission
        )
        .then(function (response) {
          // Reset loading indicator after the request is completed

          _this.loadingOrganizations = false;

          // Check the success status of the response
          if (response.success) {
            // Hide the organization editing modal and refresh the organization list
            _this.$bvModal.hide("modal-edit-org");
            _this.getOrganizationList();
          } else {
            // Display a sweet alert with the error message from the response
            swal(response.message);
          }
        });
    },

    getOrganizationList() {
      // Initialize local variable for component instance
      let _this = this;

      // Check if the user has read access to retrieve the organization list
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myOrgRole,
        this.access_property,
        "read"
      );

      // Set loading indicator for retrieving organization list
      _this.loadingOrganizations = true;

      // Call the getOrganizationList method in the HttpRequest module
      return httpRequest.getOrganizationList(accessPermission).then(function (response) {
        // Reset loading indicator after the request is completed
        _this.loadingOrganizations = false;

        // Check the success status of the response
        if (response.success) {
          // Update the organizationList with the received data
          _this.organizationList = response.data;

          // Clear the organizationTableData before updating
          _this.organizationTableData.length = 0;
          let tempObj = {};

          // Loop through the organizationList to construct organizationTableData
          _this.organizationList.forEach(function (orgData, index) {
            // Determine the structure of tempObj based on accessPermission
            if (!accessPermission) {
              tempObj = {
                serial_number: index + 1,
                name: orgData.organization.name,
                address: orgData.organization.address,
                organization_id: orgData.organization.organization_id,
              };
            } else {
              tempObj = {
                serial_number: index + 1,
                name: orgData.name,
                address: orgData.address,
                organization_id: orgData.organization_id,
              };
            }

            // Push tempObj to organizationTableData
            _this.organizationTableData.push(tempObj);

            // Update selected and other properties based on the first element in organizationTableData
            if (_this.organizationTableData.length > 0) {
              _this.selected = _this.organizationTableData[0];
              _this.selectedOrgName = _this.organizationTableData[0].name;
              _this.selectedOrganizationID =
                _this.organizationTableData[0].organization_id;
              _this.getOrgMembersList(_this.selectedOrganizationID);
            }
          });
        }
        // Return undefined
        return;
      });
    },

    addOrgMember() {
      // Initialize local variable for component instance
      let _this = this;

      // Set loading indicator for adding organization member
      _this.loadingMembers = true;

      // Check if the user has write access to add a member to the organization
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myOrgRole,
        "Member",
        "write"
      );

      // Initialize sweet alert for displaying messages
      let swal = this.$swal;

      // Get the user role ID based on the selected memberUserRole
      let userRoleID = this.roleIDMap.get(_this.memberUserRole);

      // Call the addMemberToOrganization method in the HttpRequest module
      return httpRequest
        .addMemberToOrganization(
          _this.selectedOrgID,
          _this.memberUserEmail,
          _this.memberUserName,
          userRoleID,
          accessPermission
        )
        .then(function (response) {
          // Reset loading indicator after the request is completed
          _this.loadingMembers = false;

          // Check the success status of the response
          if (response.success) {
            // Refresh the organization members list, hide the modal, and display success message
            _this.getOrgMembersList(_this.selectedOrgID);
            _this.$bvModal.hide("modal-1");
            swal("Member adding mail has been sent.");

            // Reset memberUserName and memberUserEmail after successful addition
            _this.memberUserName = "";
            _this.memberUserEmail = "";
          } else {
            // Display a sweet alert with the error message from the response
            swal(response.message);
          }

          // Return undefined
          return;
        });
    },

    getRoleList(role_type) {
      // Initialize local variable for component instance
      let _this = this;

      // Call the getRole method in the HttpRequest module
      httpRequest.getRole(role_type).then(function (response) {
        // Check the success status of the response
        if (response.success) {
          // Update the currentRoleList with the received data
          _this.currentRoleList = response.data;

          // For avoiding data collision, set roleObjectList values using Vue.set
          Vue.set(_this.roleObjectList, 5, "Member");
          Vue.set(_this.roleObjectList, 4, "Owner");
        }
      });
    },

    getOrgMembersList(org_id) {
      // Initialize local variable for component instance
      let _this = this;

      // Set loading indicator for retrieving organization members list
      _this.loadingMembers = true;

      // Call the getOrganizationMembers method in the HttpRequest module
      httpRequest.getOrganizationMembers(org_id).then(function (response) {
        // Reset loading indicator after the request is completed
        _this.loadingMembers = false;

        // Set the selectedOrgID to the provided org_id
        _this.selectedOrgID = org_id;

        // Check the success status of the response
        if (response.success) {
          // Update the orgMembersList with the received data
          _this.orgMembersList = response.data;

          // Clear the orgMemberTableData before updating
          _this.orgMemberTableData.length = 0;
          let tempObj = {};

          // Loop through the orgMembersList to construct orgMemberTableData
          _this.orgMembersList.forEach(function (orgMemberData, index) {
            // Check if the current member is the logged-in user and update myOrgRole
            if (
              orgMemberData.user.name == _this.myName &&
              orgMemberData.user.email == _this.myEmail
            ) {
              _this.myOrgRole = orgMemberData.role_id;
              _this.addColumn(_this.myOrgRole.toString());
            }

            // Structure tempObj based on orgMemberData
            tempObj = {
              serial_number: index + 1,
              name: orgMemberData.user.name,
              email: orgMemberData.user.email,
              user_id: orgMemberData.user.user_id,
              user_role_id: orgMemberData.user.role_id,
              role_id: _this.displayValueMap.get(orgMemberData.role_id),
            };

            // Push tempObj to orgMemberTableData
            _this.orgMemberTableData.push(tempObj);
          });
        } else {
          // Set orgMembersList to an empty array if the response is not successful
          _this.orgMembersList = [];
        }
      });
    },

    removeOrganizationMember(userData) {
      // Initialize local variable for component instance
      let _this = this;

      // Check if the user has delete access to remove a member from the organization
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myOrgRole,
        "Member",
        "delete"
      );

      // Display a confirmation dialog using Swal (SweetAlert)
      _this
        .$swal({
          text: "Are you sure you want to remove this member?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "CANCEL",
        })
        .then((result) => {
          // Check if the user confirmed the removal
          if (result.value) {
            // Call the deleteMemberFromOrganization method in the HttpRequest module
            httpRequest
              .deleteMemberFromOrganization(
                this.selectedOrganizationID,
                userData.email,
                accessPermission
              )
              .then(function (response) {
                // Check the success status of the response
                if (response.success) {
                  // Refresh the organization members list after successful removal
                  _this.getOrgMembersList(_this.selectedOrganizationID);
                } else {
                  // Display a Swal alert indicating the inability to remove the member
                  _this.$swal({
                    text: "You can't remove this member!",
                  });
                }
              });
          }
        });
    },

    editOrganizationMember() {
      // Initialize local variable for component instance
      let _this = this;

      // Check if the user has update access to edit an organization member
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myOrgRole,
        "Member",
        "update"
      );

      // Set loading indicator for updating organization member
      _this.loadingMembers = true;

      // Initialize sweet alert for displaying messages
      let swal = this.$swal;

      // Get the user role ID based on the roleEditItem's role_id
      let userRoleID = this.roleIDMap.get(_this.roleEditItem.role_id);

      // Call the updateOrganizationMember method in the HttpRequest module
      return httpRequest
        .updateOrganizationMember(
          _this.selectedOrgID,
          _this.roleEditItem.email,
          _this.roleEditItem.name,
          userRoleID,
          accessPermission
        )
        .then(function (response) {
          // Reset loading indicator after the request is completed
          _this.loadingMembers = false;

          // Check the success status of the response
          if (response.success) {
            // Refresh the organization members list, hide the modal, and display success message
            _this.getOrgMembersList(_this.selectedOrgID);
            _this.$bvModal.hide("modal-edit-role");
            swal(response.message);
          } else {
            // Display a sweet alert with the error message from the response
            swal(response.message);
          }

          // Return undefined
          return;
        });
    },

    onFilteredOrg(filteredItems) {
      // Update totalRowsOrg and reset currentPage when organization list is filtered
      this.totalRowsOrg = filteredItems.length;
      this.currentPage = 1;
    },

    onFilteredOrgMembers(filteredItems) {
      // Update totalRowsMembers and reset currentPage when organization members list is filtered
      this.totalRowsMembers = filteredItems.length;
      this.currentPage = 1;
    },

    myRowClickHandler(record) {
      // Handle row click event to update selected details and refresh organization members list
      this.selected = record;
      this.selectedOrgName = record.name;
      this.selectedOrganizationID = record.organization_id;
      this.getOrgMembersList(record.organization_id);
    },

    myEditedItem(row) {
      // Set editedItem with the selected row for editing
      this.editedItem = row;
    },

    rowClass(item, type) {
      // Apply custom class to the active table row
      if (!item || type !== "row") return;
      if (item == this.selected) {
        return "table-active";
      }
    },

    myRoleEditItem(item) {
      // Set roleEditItem with the selected item for role editing
      this.roleEditItem = item;
    },

    deleteDepartment(projectData) {
      // Initialize local variable for component instance
      let _this = this;

      // Display a confirmation dialog using Swal (SweetAlert)
      _this
        .$swal({
          text: "Are you sure you want to delete this department?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "CANCEL",
        })
        .then((result) => {
          // Check if the user confirmed the deletion
          if (result.value) {
            // Check if the user has delete access to delete the department
            let accessPermission = this.doubleRoleCheck(
              this.role,
              this.myOrgRole,
              this.access_property,
              "delete"
            );

            // Call the deleteDepartment method in the HttpRequest module
            httpRequest
              .deleteDepartment(projectData.organization_id, accessPermission)
              .then(function (response) {
                // Check the success status of the response
                if (response.success) {
                  // Refresh the organization list and display a success message
                  _this.getOrganizationList();
                  _this.$swal({
                    text: "Delete Successful",
                  });
                } else {
                  // Display a Swal alert indicating the inability to delete the department
                  _this.$swal({
                    text: "You can't delete this department!",
                  });
                }
              });
          }
        });
    },
  },
  async created() {
    this.roleIDMap = new Map();
    this.roleIDMap.set("User", 1);
    this.roleIDMap.set("Admin", 2);
    this.roleIDMap.set("Super Admin", 3);
    this.roleIDMap.set("Owner", 4);
    this.roleIDMap.set("Member", 5);
    this.roleIDMap.set("Product Owner", 6);
    this.roleIDMap.set("Project Manager", 7);
    this.roleIDMap.set("Project Member", 8);
    this.roleIDMap.set("Developer", 9);
    this.roleIDMap.set("QA", 10);
    this.roleIDMap.set("Designer", 11);

    this.displayValueMap = new Map();
    this.displayValueMap.set(1, "User");
    this.displayValueMap.set(2, "Admin");
    this.displayValueMap.set(3, "Super Admin");
    this.displayValueMap.set(4, "Owner");
    this.displayValueMap.set(5, "Member");
    this.displayValueMap.set(6, "Product Owner");
    this.displayValueMap.set(7, "Project Manager");
    this.displayValueMap.set(8, "Project Member");
    this.displayValueMap.set(9, "Developer");
    this.displayValueMap.set(10, "QA");
    this.displayValueMap.set(11, "Designer");

    // Initialize organizationMap as a new Map instance
    this.organizationMap = new Map();

    // Fetch and set the role list for organizations
    await this.getRoleList("org");

    // Fetch and set the organization list
    await this.getOrganizationList();

    // Get and set the user_id from the profile data stored in preferences
    this.user_id = preference.getProfileData().userId;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!--Organization List block-->
      <div class="col-xl-4 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">Department List</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="orgPerPage"
                      size="sm"
                      :options="orgPageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterOrg"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Organization List table -->
            <b-table
              show-empty
              class="mb-0"
              ref="organizationTable"
              :items="organizationTableData"
              :fields="organizationFields"
              :striped="false"
              responsive=""
              :per-page="orgPerPage"
              :current-page="orgCurrentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filterOrg"
              :filter-included-fields="filterOnOrg"
              :bordered="true"
              :hover="false"
              :dark="false"
              :tbody-tr-class="rowClass"
              @filtered="onFilteredOrg"
              @row-clicked="myRowClickHandler"
              :busy="loadingOrganizations"
            >
              <!-- Header template for each field in organizationFields -->
              <template
                v-for="field in organizationFields"
                v-slot:[`head(${field.key})`]="{ label }"
              >
                <!-- Customize header style with specified color -->
                <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
                  label
                }}</span>
              </template>

              <!-- Custom actions column template -->
              <template slot="actions" slot-scope="projectList">
                <b-btn size="sm" @click="myRowClickHandler(projectList.item)"
                  >Details</b-btn
                >
              </template>

              <!-- Custom cell template for 'domain' field with a clickable link -->
              <template v-slot:cell(domain)="row">
                <b-link :href="row.item.domain">{{ row.item.domain }}</b-link>
              </template>

              <!-- Custom cell template for 'action' field with edit and delete buttons -->
              <template
                v-slot:cell(action)="row"
                v-if="doubleRoleCheck(role, myOrgRole, access_property, 'read')"
              >
                <div style="display: flex; gap: 8px">
                  <!-- Edit button -->
                  <b-button
                    v-b-modal.modal-edit-org
                    variant="outline-info"
                    size="sm"
                    v-if="doubleRoleCheck(role, myOrgRole, access_property, 'update')"
                    @click="myEditedItem(row.item)"
                  >
                    <i class="ri-pencil-fill"></i>
                  </b-button>

                  <!-- Delete button -->
                  <b-button
                    v-if="doubleRoleCheck(role, myOrgRole, access_property, 'delete')"
                    variant="outline-danger"
                    size="sm"
                    v-on:click="deleteDepartment(row.item)"
                  >
                    <i class="ri-delete-bin-line"></i>
                  </b-button>
                </div>
              </template>

              <!-- Loading spinner template -->
              <template #table-busy>
                <div class="text-center">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>

            <!--Organization List modals-->
            <div class="align-items-center text-center text-md-left">
              <!-- Bootstrap Vue modal for adding a new organization -->
              <b-modal id="modal-add-org" centered title="Add Department" hide-footer>
                <!-- Form for capturing organization details -->
                <form class="form-horizontal" @submit.prevent="">
                  <!-- Organization Name Input -->
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-building-2-line auti-custom-input-icon"></i>
                    <label for="orgname">Department Name</label>
                    <!-- Input field for organization name -->
                    <input
                      type="text"
                      class="form-control"
                      id="orgname"
                      placeholder="Enter name"
                      v-model="org_name"
                    />
                  </div>
                  <!-- Organization Details Input -->
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="mdi mdi-home-search-outline auti-custom-input-icon"></i>
                    <label for="orgadress">Details</label>
                    <!-- Input field for organization details -->
                    <input
                      type="text"
                      class="form-control"
                      id="orgadress"
                      placeholder="Enter details"
                      v-model="org_address"
                    />
                  </div>
                  <!-- Submit Button -->
                  <div class="mt-4 text-center">
                    <!-- SUBMIT button triggering createOrganization method on click -->
                    <b-button
                      class="customs-bg-color"
                      variant="primary"
                      size="lg"
                      type="submit"
                      v-on:click="createOrganization()"
                    >
                      SUBMIT
                      <!-- Arrow icon for visual cue -->
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>

              <!-- Bootstrap Vue modal for editing an existing organization -->
              <b-modal id="modal-edit-org" centered title="Edit Department" hide-footer>
                <!-- Form for updating organization details -->
                <form class="form-horizontal" @submit.prevent="">
                  <!-- Organization Name Input -->
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-building-2-line auti-custom-input-icon"></i>
                    <label for="org_name">Department Name</label>
                    <!-- Input field for editing organization name with v-model binding to editedItem.name -->
                    <input
                      type="text"
                      class="form-control"
                      id="org_name"
                      placeholder="Enter name"
                      v-model="editedItem.name"
                    />
                  </div>
                  <!-- Organization Address Input -->
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="mdi mdi-home-search-outline auti-custom-input-icon"></i>
                    <label for="org_address">Address</label>
                    <!-- Input field for editing organization address with v-model binding to editedItem.address -->
                    <input
                      type="text"
                      class="form-control"
                      id="org_address"
                      placeholder="Enter address"
                      v-model="editedItem.address"
                    />
                  </div>
                  <!-- Update Button -->
                  <div class="mt-4 text-center">
                    <!-- UPDATE button triggering editOrganization method on click -->
                    <b-button
                      class="customs-bg-color"
                      variant="primary"
                      size="lg"
                      type="submit"
                      v-on:click="editOrganization"
                    >
                      UPDATE
                      <!-- Arrow icon for visual cue -->
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>

          <div class="card-footer bg-white">
            <!-- Row for ADD DEPARTMENT button and pagination -->
            <div class="row">
              <!-- Column for ADD DEPARTMENT button -->
              <div class="col-md-auto mb-2 mb-md-0 text-center text-md-left">
                <!-- ADD DEPARTMENT button triggering modal with v-b-modal directive -->
                <b-button
                  v-b-modal.modal-add-org
                  variant="primary"
                  class="customs-bg-color"
                  v-if="doubleRoleCheck(this.role, myOrgRole, access_property, 'write')"
                >
                  <!-- Icon for visual cue -->
                  <i class="ri-add-line" style="vertical-align: bottom"></i> ADD
                  DEPARTMENT
                </b-button>
              </div>
              <!-- Column for pagination -->
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <!-- Bootstrap Vue pagination component with various props -->
                  <b-pagination
                    v-model="orgCurrentPage"
                    :limit="3"
                    :total-rows="orgRows"
                    :per-page="orgPerPage"
                    class="pagination-rounded mb-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Organization member block-->
      <div class="col-xl-8 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">Members of {{ selectedOrgName }}</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="members-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="memberPerPage"
                      size="sm"
                      :options="memberPageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="members-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterOrgMembers"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Organization member table -->
            <b-table
              show-empty
              class="mb-0"
              :striped="false"
              :bordered="true"
              :hover="false"
              :dark="false"
              :fields="orgMemberFields"
              :items="orgMemberTableData"
              :busy="loadingMembers"
              responsive=""
              :per-page="memberPerPage"
              :current-page="memberCurrentPage"
              :filter="filterOrgMembers"
              :filter-included-fields="filterOnMembers"
              @filtered="onFilteredOrgMembers"
            >
              <!-- Header template for each field in orgMemberFields -->
              <template
                v-for="field in orgMemberFields"
                v-slot:[`head(${field.key})`]="{ label }"
              >
                <!-- Displaying field label with custom styling -->
                <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
                  label
                }}</span>
              </template>

              <!-- Template for displaying role_id as badge with different variants -->
              <template v-slot:cell(role_id)="row">
                <!-- Displaying role_id as a badge with conditional styling -->
                <b-badge pill variant="secondary" v-if="row.item.role_id == 'Member'">
                  {{ row.item.role_id }}
                </b-badge>
                <b-badge pill variant="primary" v-else>
                  {{ row.item.role_id }}
                </b-badge>
              </template>

              <!-- Template for displaying actions (edit and delete) with conditional rendering -->
              <template
                v-slot:cell(action)="row"
                v-if="doubleRoleCheck(role, myOrgRole, 'Member', 'read')"
              >
                <!-- Container for action buttons with flex display and gap -->
                <div style="display: flex; gap: 8px">
                  <!-- Edit button triggering modal with v-b-modal directive -->
                  <b-button
                    v-b-modal.modal-edit-role
                    v-if="doubleRoleCheck(role, myOrgRole, 'Member', 'update')"
                    variant="outline-info"
                    size="sm"
                    v-on:click="myRoleEditItem(row.item)"
                  >
                    <!-- Icon for visual cue -->
                    <i class="ri-pencil-fill"></i>
                  </b-button>
                  <!-- Delete button triggering method on click with v-on directive -->
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    v-if="doubleRoleCheck(role, myOrgRole, 'Member', 'delete')"
                    v-on:click="removeOrganizationMember(row.item)"
                  >
                    <!-- Icon for visual cue -->
                    <i class="ri-delete-bin-line"></i>
                  </b-button>
                </div>
              </template>

              <!-- Loading template displayed when table is busy -->
              <template #table-busy>
                <!-- Centered loading spinner -->
                <div class="text-center">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>

            <!--Organization member modal-->
            <div class="align-items-center text-center text-md-left">
              <!-- Modal for adding a member -->
              <b-modal id="modal-1" centered title="Add member" hide-footer>
                <!-- Form for adding a member with prevent default on submit -->
                <form class="form-horizontal" @submit.prevent="">
                  <!-- Input field for member's name -->
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-user-line auti-custom-input-icon"></i>
                    <label for="username">Name</label>
                    <input
                      v-model="memberUserName"
                      type="text"
                      class="form-control"
                      id="username"
                      placeholder="Enter name"
                    />
                  </div>

                  <!-- Input field for member's email -->
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-mail-line auti-custom-input-icon"></i>
                    <label for="useremail">Email</label>
                    <input
                      v-model="memberUserEmail"
                      type="email"
                      class="form-control"
                      id="useremail"
                      placeholder="Enter email"
                    />
                  </div>

                  <!-- Select field for member's role, displayed only for a specific role -->
                  <div
                    v-if="this.role == 3"
                    class="form-group auth-form-group-custom mb-4"
                  >
                    <i class="ri-medal-line auti-custom-input-icon"></i>
                    <label for="role">Role</label>
                    <select
                      class="form-control custom-select"
                      id="role"
                      v-model="memberUserRole"
                    >
                      <!-- Options for the roles using v-for loop -->
                      <option v-for="(value, key) in roleObjectList" :key="key">
                        {{ value }}
                      </option>
                    </select>
                  </div>

                  <!-- Submit button for adding a member -->
                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      class="customs-bg-color"
                      size="lg"
                      type="submit"
                      v-on:click="addOrgMember"
                    >
                      SUBMIT
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>

              <!-- Modal for editing a member -->
              <b-modal id="modal-edit-role" centered title="Edit member" hide-footer>
                <!-- Form for editing a member with appropriate input fields -->
                <form class="form-horizontal" @submit.prevent="">
                  <!-- Input field for Name -->
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-user-line auti-custom-input-icon"></i>
                    <label for="username">Name</label>
                    <input
                      v-model="roleEditItem.name"
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Enter name"
                    />
                  </div>

                  <!-- Input field for Email -->
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-mail-line auti-custom-input-icon"></i>
                    <label for="useremail">Email</label>
                    <input
                      v-model="roleEditItem.email"
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Enter email"
                    />
                  </div>

                  <!-- Select field for Role -->
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-medal-line auti-custom-input-icon"></i>
                    <label for="role">Role</label>
                    <select
                      class="form-control custom-select"
                      id="role_id"
                      v-model="roleEditItem.role_id"
                    >
                      <!-- Options dynamically generated based on roleObjectList -->
                      <option v-for="(value, key) in roleObjectList" :key="key">
                        {{ value }}
                      </option>
                    </select>
                  </div>

                  <!-- Submit button -->
                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      class="customs-bg-color"
                      size="lg"
                      type="submit"
                      v-on:click="editOrganizationMember"
                    >
                      SUBMIT
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>

          <div class="card-footer bg-white">
            <!-- Organization member button and pagination -->
            <div class="row">
              <!-- Button to add a member -->
              <div class="col-md-auto mb-2 mb-md-0 text-center text-md-left">
                <b-button
                  v-b-modal.modal-1
                  variant="primary"
                  v-if="doubleRoleCheck(this.role, myOrgRole, 'Member', 'write')"
                  class="customs-bg-color"
                >
                  <i class="ri-add-line" style="vertical-align: bottom"></i> ADD MEMBER
                </b-button>
              </div>

              <!-- Pagination for organization members -->
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <!-- Pagination control -->
                  <b-pagination
                    v-model="memberCurrentPage"
                    :limit="3"
                    :total-rows="memberRows"
                    :per-page="memberPerPage"
                    class="pagination-rounded mb-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}
</style>
